import React, { createContext, useState, useContext } from "react";
import ApiManager from "../utils/ApiManager";

const AuthContext = createContext();
let _currentUser = null;
let _loginIsValid = false;

const getUserFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && validateUser(user)) {
    _currentUser = user;
    _loginIsValid = true;
    return user;
  }
  return null;
};

const validateUser = (user) => {
  return (
    user &&
    user.username &&
    user.username !== "" &&
    new Date(user.expireOn) >= new Date() &&
    user.token &&
    user.token !== ""
  );
};

const updateLocalStorage = (user) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.removeItem("user");
  }
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(getUserFromLocalStorage());
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  

  const login = async (username, password) => {
    let result = false;
    await ApiManager.Top.login(
      {
        username: username,
        password: password,
      },
      (userData) => {
        _loginIsValid = validateUser(userData);
        if (_loginIsValid) {
          setCurrentUser(userData);
          _currentUser = userData;
          updateLocalStorage(userData);
          result = true;
        } else {
          throw new Error("Nome utente o password non validi.");
        }
      },
      (error) => {
        console.error("Login failed", error);
        throw new Error("Errore durante il login. Riprova.");
      },
      () => {}
    );
    return result;
  };

  const logout = () => {
    setCurrentUser(null);
    updateLocalStorage(null);
    _currentUser = null;
    _loginIsValid = false;
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        login,
        logout,
        loginIsValid: _loginIsValid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const getCurrentUser = () => _currentUser;
export const getLoginIsValid = () => _loginIsValid;
